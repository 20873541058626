/**
 * @name: 商品管理-商品信息管理接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 商品管理-商品信息管理接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IProductList, IProductListParam} from "@/apis/product/list/types";

/**
 * 分页查询
 * @param param
 */
export const productQueryApi = (param: IProductListParam) => get<IPageRes<IProductList[]>>("/card/product/query", param)
/**
 * 创建
 * @param data
 */
export const productCreateApi = (data: Partial<IProductList>) => postJ<IPageRes<IProductList[]>>("/card/product/create", data)
/**
 * 修改
 * @param data
 */
export const productModifyApi = (data: Partial<IProductList>) => postJ<IPageRes<IProductList[]>>("/card/product/modify", data)
/**
 * 商品导出
 * @param param
 */
export const productExportApi = (param: IProductListParam) => get("/card/product/export", param, "blob")
/**
 * 详情
 * @param id
 */
export const productDetailApi =  (id: string) => get<IProductList>("/card/product/detail/" + id)
