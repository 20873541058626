
/**
 * @name: 商品管理-商品信息管理
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 商品管理-商品信息管理
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import {
  productCreateApi,
  productDetailApi,
  productExportApi,
  productModifyApi,
  productQueryApi
} from "@/apis/product/list"
import { IProductList, IProductListParam } from "@/apis/product/list/types"
import config from "@/config";
import type { ICrudOption } from "@/types/m-ui-crud"
import {exportFile} from "@/utils/common";

@Component({})
export default class productList extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IProductList[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IProductListParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IProductList> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    delBtn: false,
    column: [
      {
        label: "商品",
        prop: "productId",
        placeholder: "商品ID/商品名称",
        search: true,
        hide: true,
        addHide: true,
        editHide: true
      },
      {
        label: "商品ID",
        prop: "productId",
        addHide: true,
        editHide: true,
        align: "left"
      },
      {
        label: "商品名称",
        prop: "productName",
        align: "center",
        span: 12,
        rules: [
          {required: true, message: '商品名称不能为空', trigger: 'blur'},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(value)) {
                return callback(new Error('只支持中文、字母、数字'))
              }
              callback()
            },
            trigger: "blur"
          }
        ],
        overHidden: true,
        maxlength: 20
      },
      {
        label: "商品分类",
        prop: "productSortId",
        hide: true,
        search: true,
        span: 12,
        type: "select",
        dicUrl: "/api/card/productSort/dropDown",
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'productSortId'}),
        addHide: true,
        editHide: true
      },
      {
        label: "商品分类",
        prop: "productSortId",
        hide: true,
        search: false,
        span: 12,
        type: "select",
        dicUrl: "/api/card/productSort/dropDown?status=1",
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'productSortId'}),
        rules: [
          {required: true, message: "商品分类不能为空", trigger: "blur"}
        ]
      },
      {
        label: "商品分类",
        prop: "sortName",
        addHide: true,
        editHide: true,
        align: "center",
        overHidden: true
      },
      {
        label: "排序",
        prop: "productSort",
        align: "center",
        type: "number",
        span: 12,
        rules: [
          {required: true, message:  "排序不能为空", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!value || value == '0') {
                return callback(new Error('排序不能为空和0'))
              }
              if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
                return callback(new Error('排序只能正整数'))
              }
              if (parseInt(value) > 9999) {
                return callback(new Error('排序最大9999'))
              }
              callback()
            },
            trigger: "blur"
          }
        ]
      },
      {
        label: "商品售价(元)",
        prop: "productPrice",
        align: "center",
        type: "number",
        span: 12,
        rules: [
          {required: true, message: "商品售价不能为空", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!value || value == '0') {
                return callback(new Error('商品售价不能为空和0'))
              }
              if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                return callback(new Error('请输入有效的商品售价'))
              }
              if (parseFloat(value) > 999999.99) {
                return callback(new Error('商品售价最大999999.99'))
              }
              callback()
            },
            trigger: "blur"
          }
        ]
      },
      {
        label: "商品图片",
        prop: "productPic",
        type: "image",
        align: "center",
        span: 24,
        imgPrefix: config.downloadUrl,
        rules: [
          {required: true, message:  "商品图片不能为空", trigger: "blur"}
        ],
        addSlot: true,
        editSlot: true
      },
      {
        label: "商品详情",
        prop: "productDetails",
        hide: true,
        span: 24,
        addSlot: true,
        editSlot: true,
        rules: [
          {required: true, message: "商品详情不能为空", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (value.length > 800) {
                return callback(new Error('商品详情最多800个字符'))
              }
              callback()
            }
          }
        ]
      },
      {
        label: "状态",
        prop: "productStatus",
        align: "center",
        search: true,
        addHide: true,
        editHide: true,
        slot: true,
        type: "select",
        dicData: [
          {
            label: "上架",
            value: 1
          },
          {
            label: "下架",
            value: 2
          }
        ]
      }
    ]
  }

  getList () {
    this.tableLoading = true
    productQueryApi(this.queryParam).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  beforeUpload (file: File) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isJPG) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 1MB!');
    }
    return isJPG && isLt2M;
  }

  rowSave (form: IProductList, done: Function, loading: Function) {
    productCreateApi(form).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IProductList, done: Function, loading: Function) {
    productModifyApi(form).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  statusChange (productId: string, productStatus: number) {
    this.tableLoading = true
    productModifyApi({productId, productStatus}).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      this.tableLoading = false
    })
  }

  exportExcel () {
    productExportApi(this.queryParam).then(e => {
      exportFile(e, "商品信息.xlsx")
    })
  }

  openEdit (row: IProductList, index: number) {
    productDetailApi(row.productId).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e, index)
      }
    })
  }

  created () {
    this.getList()
  }
}
